import Sidebar from '../components/layout/sidebar/sidebar'
import { MainView } from '../components/layout/main'
import { ViewTitle } from '../components/reusable'
import { ReportLinksView } from '../components/views/reportLinksView'

function ReportLinks() {
    return (
        <div className="reportLinks">
            <Sidebar />
            <MainView>
                <ViewTitle text={'Report Links'} />
                <ReportLinksView />
            </MainView>
        </div>
    )
}

export default ReportLinks
