import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { FileItem, StatTile, ViewTitle } from '../../../../reusable'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { Confirm } from 'notiflix/build/notiflix-confirm-aio'

import { useNavigate } from 'react-router-dom'
import api from '../../../../../helpers/apiRequester'
import Select from 'react-select'
import { ProgressView } from '../progressView'
import { CancelAction } from './actions/cancel'
import { LoaAction } from './actions/loa'

export const ActionsView = (props: { caseRef: string; cancelledStatus: boolean; part36: any }) => {
    const { caseRef, cancelledStatus, part36 } = props

    const hasPart36 = () => {
        if (!part36.sf || part36.sf == '') return false
        if (!part36.cn || part36.cn == '') return false
        if (!part36.ds || part36.ds == '') return false
        return true
    }
    return (
        <div className={styles.actionsView}>
            <h4>Case Actions</h4>
            <div className={styles.viewFilesContainer}>
                {/* LBA ACTIONS */}
                {/* <LbaAction caseRef={caseRef} /> */}
                {/* {hasPoc ? <PocAction caseRef={caseRef} pocLink={pocLink} /> : <></>} OLD POC v2*/}
                {/* {hasPoc ? <PocV3Action caseRef={caseRef} pocLink={pocLink} /> : <></>} */}
                <CancelAction caseRef={caseRef} cancelled={cancelledStatus} />
                {/* {hasPart36() ? <Part36Action caseRef={caseRef} /> : <></>} */}
                {/* <LoaAction caseRef={caseRef} /> */}
                {/* 
                <div className={styles.singleAction}>
                    <h4>FOA Response to lender</h4>
                    <div>
                        <p>
                            <span>Send the FOA to lender:</span> <button>Send</button>
                        </p>
                    </div>
                </div> */}
            </div>
        </div>
    )
}
