import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { StatTile, ViewTitle } from '../../../../reusable'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { useNavigate } from 'react-router-dom'
import api from '../../../../../helpers/apiRequester'
import Select from 'react-select'
export interface statsProps {
    data: any
}
export interface stepInferface {
    title: string
    progress: string
}
export const ProgressView = (props: statsProps) => {
    const navigate = useNavigate()
    const { data } = props
    const [steps, setSteps] = useState<Array<stepInferface>>([])

    const progressItems: any = {
        incomplete: (
            <>
                <div className={styles.incomplete}></div>
            </>
        ),
        complete: (
            <>
                <div className={styles.complete}></div>
            </>
        ),
        cancelled: (
            <>
                <div className={styles.cancelled}></div>
            </>
        ),
    }

    useEffect(() => {
        console.log('DATAVIEW : ', data)

        let ccResult = data.credit_check
        if (!data.credit_check.includes('not started')) {
            const ccJson = JSON.parse(data.credit_check)
            ccResult = ccJson.status == 'failed' ? 'failed' : 'success'
        }

        const stepsArray = [
            { title: 'signature verification', progress: data.signature_verification },
            { title: 'id verification', progress: data.id_verification },
            { title: 'credit check', progress: ccResult },
            { title: 'policy associated', progress: data.cc_policy_association },
            { title: 'Cancelled', progress: data.cancelled == '1' ? 'YES' : 'NO' },
        ]
        setSteps(stepsArray)
    }, [data])

    const isComplete = (step: stepInferface) => {
        if (step.title == 'signature verification' && step.progress == 'check') {
            return styles.orange
        }
        if (step.title == 'signature verification' && step.progress == 'pass') {
            return styles.success
        }
        if (step.title == 'id verification' && step.progress == 'pass') {
            return styles.success
        }
        if (step.title == 'id verification' && step.progress == 'fail') {
            return styles.fail
        }
        if (step.title == 'credit check' && step.progress == 'failed') {
            return styles.fail
        }
        if (step.title == 'credit check' && step.progress == 'success') {
            return styles.success
        }
        if (step.title == 'policy associated' && step.progress == 'Success') {
            return styles.success
        }
        if (step.title == 'policy associated' && step.progress == 'Failed') {
            return styles.fail
        }
        if (step.title == 'Cancelled' && step.progress == 'YES') {
            return styles.fail
        }
    }

    return (
        <div className={styles.progressViewContainer}>
            <div className={styles.progressView}>
                {steps &&
                    steps?.map((step: stepInferface, k: number) => {
                        return (
                            <div key={k} className={styles.ProgressItemContainer}>
                                <div className={styles.progessCircleContainer}>
                                    <div className={`${isComplete(step)} ${styles.progessCircle}`}></div>
                                </div>
                                <p>
                                    <span>{step.title}</span>
                                    <span>{step.progress}</span>
                                </p>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}
