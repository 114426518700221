import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { StatTile, ViewTitle } from '../../../../reusable'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { useNavigate } from 'react-router-dom'
import api from '../../../../../helpers/apiRequester'
import Select from 'react-select'
import { selectObjectProps } from '../../../../../interfaces'
import { feeEarners } from '../../../../../data'
// import { PocModalView } from "../../../pocModalView";
export interface statsProps {
    data: any
}

export const CaseDetailsView = (props: statsProps) => {
    const { data } = props
    const [editing, setEditing] = useState(false)
    const [hasCCData, setHasCCData] = useState(true)
    const [caseData, setCaseData] = useState(data)
    const [feeEarner, setFeeEarner] = useState(feeEarners.find((fe) => fe.value == data.fee_earner))
    const [dataChanged, setDataChanged] = useState(false)
    const [addPocModal, setAddPocModal] = useState(false)

    useEffect(() => {
        // console.log("CASE INFO DATAVIEW : ", data);
        setCaseData(data)
        console.log(data)
        setHasCCData(data.cc_policy_association == 'Success')
    }, [data])

    // useEffect(() => {
    //     // console.log("CASE INFO DATAVIEW : ", data);
    //     // setCaseData(data);
    //     console.log("case data: ", caseData);
    // }, [caseData]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
    }

    const onChange = async (e: React.FormEvent<HTMLInputElement>) => {
        let tempCaseData = { ...caseData }
        tempCaseData[e.currentTarget.name] = e.currentTarget.value
        setCaseData(tempCaseData)
        setDataChanged(true)
    }

    const saveData = async () => {
        Loading.circle('Saving')
        setEditing(false)
        console.log('SAVE DATA......')
        try {
            const apiResult = await api({
                url: 'save-case-info',
                data: { caseDataToSave: caseData },
            })
            Loading.remove()
            if (!apiResult.data.success) return Report.failure('Error', 'An error occurred.', 'Okay')

            Report.success('Success', 'Your update saved successfully.', 'Okay')
        } catch (error) {
            Report.failure('Error', 'Couldnt save data.', 'Okay')
        }
    }
    const togglePocModal = (refresh: boolean | null = null) => {
        console.log('TOGGLING....refresh:', refresh)
        if (refresh) window.location.reload()
        setAddPocModal(!addPocModal)
    }

    const handleEditToggle = () => {
        if (editing) {
            setCaseData(data)
        }
        setEditing(!editing)
    }

    const onSelectChange = (option: selectObjectProps | null, label: string) => {
        console.log('input label: ', label)
        if (option != null) {
            console.log('INPUT SELECT FIELD CHANGED...', option.value)
            const updateItem = option.label
            const updateValue = option.value

            let tempCaseData = { ...caseData }
            tempCaseData.fee_earner = updateValue
            console.log('data to save : ', tempCaseData)

            setFeeEarner(feeEarners.find((fe) => fe.value == updateValue))
            setCaseData(tempCaseData)
            setDataChanged(true)
        }
    }

    return (
        <div className={styles.detailsView}>
            <h4>
                <span>Details</span>

                <span>
                    <button onClick={(e) => handleEditToggle()}>{editing ? 'Cancel' : 'Edit'}</button>
                    {editing && dataChanged ? (
                        <>
                            <button onClick={(e) => saveData()}>Save</button>
                        </>
                    ) : (
                        <></>
                    )}
                </span>
            </h4>
            <div className={styles.viewContainer}>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <fieldset>
                        <label>Lender</label>
                        <input
                            className={styles.textInput}
                            name={'lender'}
                            value={caseData.lender}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Vehicle Make</label>
                        <input
                            className={styles.textInput}
                            name={'vehicle_make'}
                            value={caseData.vehicle_make}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Vehicle year</label>
                        <input
                            className={styles.textInput}
                            name={'vehicle_manufacture_year'}
                            value={caseData.vehicle_manufacture_year}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Vehicle fuel type</label>
                        <input
                            className={styles.textInput}
                            name={'vehicle_fuel_type'}
                            value={caseData.vehicle_fuel_type}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Vehicle Registration</label>
                        <input
                            className={styles.textInput}
                            name={'vehicle_registration'}
                            value={caseData.vehicle_registration}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Interest Rate</label>
                        <input
                            className={styles.textInput}
                            name={'apr'}
                            value={caseData.apr}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Credit Amount</label>
                        <input
                            className={styles.textInput}
                            name={'finance_amount'}
                            value={caseData.finance_amount}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>

                    <fieldset></fieldset>

                    {hasCCData ? (
                        <>
                            <div className={styles.pocDataDiv}>
                                <h4>Associated Credit Report data</h4>
                                <fieldset>
                                    <label>Policy Number</label>
                                    <input
                                        className={styles.textInput}
                                        value={caseData.policy_number_serialised}
                                        name={'policy_number_serialised'}
                                        type="text"
                                        onChange={onChange}
                                        disabled={!editing}
                                    />
                                </fieldset>
                                <fieldset>
                                    <label>Raw Policy Number</label>
                                    <input
                                        className={styles.textInput}
                                        value={caseData.policy_number_raw}
                                        name={'policy_number_raw'}
                                        type="text"
                                        onChange={onChange}
                                        disabled={!editing}
                                    />
                                </fieldset>
                                <fieldset>
                                    <label>Account type</label>
                                    <input
                                        className={styles.textInput}
                                        name={'account_type'}
                                        value={caseData.account_type}
                                        type="text"
                                        onChange={onChange}
                                        disabled={!editing}
                                    />
                                </fieldset>
                                <fieldset>
                                    <label>Account Status</label>
                                    <input
                                        className={styles.textInput}
                                        value={caseData.agreement_status}
                                        name={'agreement_status'}
                                        type="text"
                                        onChange={onChange}
                                        disabled={!editing}
                                    />
                                </fieldset>
                                <fieldset>
                                    <label>Finance Agreement Date</label>
                                    <input
                                        className={styles.textInput}
                                        value={caseData.start_date}
                                        name={'start_date'}
                                        type="text"
                                        onChange={onChange}
                                        disabled={!editing}
                                    />
                                </fieldset>
                                <fieldset>
                                    <label>Finance Agreement End Date</label>
                                    <input
                                        className={styles.textInput}
                                        value={caseData.end_date}
                                        name={'end_date'}
                                        type="text"
                                        onChange={onChange}
                                        disabled={!editing}
                                    />
                                </fieldset>
                                <fieldset>
                                    <label>Settlement Date</label>
                                    <input
                                        className={styles.textInput}
                                        value={caseData.settlement_date}
                                        name={'settlement_date'}
                                        type="text"
                                        onChange={onChange}
                                        disabled={!editing}
                                    />
                                </fieldset>
                                <fieldset>
                                    <label>Total Amount Payable</label>
                                    <input
                                        className={styles.textInput}
                                        value={caseData.total_amount_payable}
                                        name={'total_amount_payable'}
                                        type="text"
                                        onChange={onChange}
                                        disabled={!editing}
                                    />
                                </fieldset>
                                <fieldset>
                                    <label>Contract term</label>
                                    <input
                                        className={styles.textInput}
                                        name={'total_contract_months'}
                                        value={caseData.total_contract_months + 'Months'}
                                        type="text"
                                        onChange={onChange}
                                        disabled={!editing}
                                    />
                                </fieldset>
                                <fieldset>
                                    <label>Monthly Repayment</label>
                                    <input
                                        className={styles.textInput}
                                        name={'monthly_payment'}
                                        value={caseData.monthly_payment}
                                        type="text"
                                        onChange={onChange}
                                        disabled={!editing}
                                    />
                                </fieldset>
                                <fieldset> </fieldset>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles.pocDataDiv}>
                                <h4>Credit check not performed - No policy data</h4>
                                <br />
                                <br />
                            </div>
                            {/* {!addPocModal ? <></> : <PocModalView case_ref={caseData.case_ref} toggleModal={togglePocModal} />} */}
                        </>
                    )}
                </form>
            </div>
        </div>
    )
}
