import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
const BASE_URL = 'https://case-delivery.sentinellegal.co.uk/report'
export const ReportLinksView = () => {
    return (
        <div className={styles.reportLinksView}>
            <div className={styles.outerReportLinksContainer}>
                <div className={styles.reportLinksContainer}>
                    <br />
                    <h2>General Reports</h2>
                    <h4>
                        <span>Funder report</span>
                        <span>
                            <a href={`${BASE_URL}/funder-report-v4`} target="__blank">
                                Download
                            </a>
                        </span>
                    </h4>
                    <h4>
                        <span>Full data report</span>
                        <span>
                            <a href={`${BASE_URL}/full-db-case-report`} target="__blank">
                                Download
                            </a>
                        </span>
                    </h4>
                    <h4>
                        <span>OA Transfer Data </span>
                        <span>
                            <a href={`${BASE_URL}/oa-transfer-stats'`} target="__blank">
                                Download
                            </a>
                        </span>
                    </h4>
                    <h4>
                        <span>Organic website leads</span>
                        <span>
                            <a href={`${BASE_URL}/website-claims-report`} target="__blank">
                                Download
                            </a>
                        </span>
                    </h4>
                    <h4>
                        <span>FOS CFA transfers</span>
                        <span>
                            <a href={`${BASE_URL}/fos-cfa-transfer-report`} target="__blank">
                                Download
                            </a>
                        </span>
                    </h4>
                    <h4>
                        <span>Pad Apps</span>
                        <span>
                            <a href={`${BASE_URL}/pad-apps-report`} target="__blank">
                                Download
                            </a>
                        </span>
                    </h4>
                    <br />
                    <h2>LOA Reports</h2>
                    <h4>
                        <span>LOAs to confirm DOB (WEBSNT)</span>
                        <span>
                            <a href={`${BASE_URL}/loa-dsar-dob-conf-report`} target="__blank">
                                Download
                            </a>
                        </span>
                    </h4>
                    <h4>
                        <span>LOAs sent from Dashboard</span>
                        <span>
                            <a href={`${BASE_URL}/loa-dsar-report`} target="__blank">
                                Download
                            </a>
                        </span>
                    </h4>
                    <h4>
                        <span>LOA requests </span>
                        <span>
                            <a href={`${BASE_URL}/foa-v2-report`} target="__blank">
                                Download
                            </a>
                        </span>
                    </h4>
                    <h4>
                        <span>WEBSNT LOA requests (outdated) </span>
                        <span>
                            <a href={`${BASE_URL}/foa-v2-report__websnt`} target="__blank">
                                Download
                            </a>
                        </span>
                    </h4>
                    <br />
                    <br />
                </div>
            </div>
        </div>
    )
}
