import { useEffect, useState } from 'react'
import axios from 'axios'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../components/layout/sidebar/sidebar'
import { MainView } from '../components/layout/main'
import { StatTile, ViewTitle } from '../components/reusable'
import api from '../helpers/apiRequester'
import { StatsView } from '../components/views'
import Select from 'react-select'

import globalStyles from '../globals/pages.module.scss'

interface selectObjectProps {
    value: string
    label: string
}

function AdlHome() {
    const navigate = useNavigate()
    const [dataReady, setDataReady] = useState(false)
    const [loading, setLoading] = useState(false)
    const [overviewData, setOverviewData] = useState<any>()
    const [activeData, setActiveData] = useState<selectObjectProps>({
        value: 'all',
        label: 'All Data',
    })

    useEffect(() => {
        if (!dataReady && !loading) {
            setLoading(true)
            getData()
        }
    }, [dataReady, loading])

    useEffect(() => {
        getData()
    }, [activeData, loading])

    const getData = async () => {
        Loading.circle('Loading...')
        try {
            const apiResponse = await api({ url: '/all-day-loans' })
            const data = apiResponse.data
            // console.log("SYSTEM OVERVIEW DATA: ", data);

            setOverviewData(data)
            setDataReady(true)
            Loading.remove()
        } catch (error: any) {
            console.log(error)
            Loading.remove()
            if (error?.response.status == 401) {
                Report.failure('Session Timed Out', 'Please log in again to continue.', 'Okay', () => {
                    window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`
                })
            } else {
                window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`
            }
        }
    }

    return (
        <div className="home">
            <Sidebar />
            <MainView>
                <ViewTitle text={'All Day Loans Lead Tracker'} />
                <div>{dataReady ? <StatsView stats={overviewData.stats} /> : <></>}</div>
            </MainView>
        </div>
    )
}

export default AdlHome
