import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { Confirm } from 'notiflix/build/notiflix-confirm-aio'

import api from '../../../../../../../helpers/apiRequester'

export const CancelAction = (props: { caseRef: string; cancelled: boolean }) => {
    const { caseRef, cancelled } = props

    const confirmCancelCase = async (action: string) => {
        Loading.circle('Cancelling case...')
        console.log('caseRef: ', caseRef)

        try {
            const apiResponse = await api({ url: 'actions/cancel', data: { case_ref: caseRef, action } })
            const data = apiResponse.data
            console.log(data)
            Loading.remove()
            if (data.success) {
                window.location.reload()
            } else {
                Report.failure('Error', 'Failed to cancel the matter', 'OKAY')
            }
        } catch (error: any) {
            console.log(error)
            Loading.remove()
            Report.failure('Error', 'Failed to cancel the matter', 'OKAY')
        }
    }
    const attemptReopenCase = async (type: string) => {
        Confirm.show(
            'Are you sure?',
            'An update will be sent to the client and the case will be reopened with updates being applied to the DB.',
            'REOPEN CASE',
            'GO BACK',
            () => confirmCancelCase(type),
            () => {}
        )
    }
    const attemptCancelCase = async (type: string) => {
        Confirm.show(
            'Are you sure?',
            cancellationType(type),
            'CANCEL CASE',
            'GO BACK',
            () => confirmCancelCase(type),
            () => {}
        )
    }

    const cancellationType = (type: string) => {
        switch (type) {
            case 'pcp-sentinel-cancellation-letter':
                return 'Mark the case cancelled by the client and inform client by email.'
            default:
                return ''
                break
        }
    }

    return (
        <div className={styles.singleAction}>
            <h4>Cancellation</h4>
            <div>
                {cancelled ? (
                    <>
                        <p>
                            <span>Reopen a cancelled case:</span>
                            <button onClick={(e: any) => attemptReopenCase('pcp-sentinel-reopen-case')}>
                                Reopen Case
                            </button>
                        </p>
                    </>
                ) : (
                    <>
                        <p>
                            <span>Cancel case on client behalf:</span>
                            <button onClick={(e: any) => attemptCancelCase('pcp-sentinel-cancellation-letter')}>
                                Client Cancellation
                            </button>
                        </p>
                        {/* 
                            <p>
                                <span>Cancel case as company dissolved: </span> <button>Company Dissolved Cancellation</button>
                            </p> 
                        */}
                    </>
                )}
            </div>
        </div>
    )
}
