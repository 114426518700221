import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { StatTile, ViewTitle } from '../../../../reusable'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { useNavigate } from 'react-router-dom'
import api from '../../../../../helpers/apiRequester'
import Select from 'react-select'
import { ProgressView } from '../progressView'
import { foaSelect, yesNo } from '../../../../../data'
import { selectObjectProps } from '../../../../../interfaces'

export interface statsProps {
    data: any
}

export const MilestonesView = (props: statsProps) => {
    const navigate = useNavigate()
    const { data } = props
    const [showStats, setShowStats] = useState(false)
    const [editing, setEditing] = useState(false)
    const [caseData, setCaseData] = useState(data)
    const [dataChanged, setDataChanged] = useState(false)

    useEffect(() => {
        // console.log("DATAVIEW : ", data);
        setCaseData(data)

        if (!showStats && data && data.length > 0) {
            // console.log("DATA AVAILABLE....", data);
            setShowStats(true)
        }
    }, [data])

    useEffect(() => {
        // console.log("DATAVIEW : ", data);
        setCaseData(data)

        if (!showStats && data && data.length > 0) {
            // console.log("DATA AVAILABLE....", data);
            setShowStats(true)
        }
    }, [data])

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
    }

    const onChange = async (e: React.FormEvent<HTMLInputElement>) => {
        let tempCaseData = { ...caseData }
        tempCaseData[e.currentTarget.name] = e.currentTarget.value
        setCaseData(tempCaseData)
        setDataChanged(true)
    }

    const saveData = async () => {
        setEditing(false)
        console.log('SAVE DATA......')
        Loading.circle('Saving....')
        try {
            const apiResult = await api({
                url: 'save-case-info',
                data: { caseDataToSave: caseData },
            })
            Loading.remove()
            if (!apiResult.data.success) {
                return Report.failure('Error', 'An error occurred.', 'Okay')
            }
            return Report.success('Success', 'Your update was saved successfully.', 'Okay')
        } catch (error: any) {
            Loading.remove()
            Report.failure('Error', `${error.message}. Please refresh the page and try again.`, 'Okay')
        }
    }

    const handleEditToggle = () => {
        if (editing) {
            setCaseData(data)
        }
        setEditing(!editing)
    }

    const onSelectChange = (option: selectObjectProps | null, label: string) => {
        console.log('input label: ', label)
        if (option != null) {
            console.log('INPUT SELECT FIELD CHANGED...', option.value)

            const field = label
            const fieldValue = option.value
            let valueToSave

            if (field == 'cancelled' || field == 'poc_n1_v2_signed' || field == 'poc_issued') {
                valueToSave = fieldValue == 'Yes' ? 1 : 0
            }
            if (
                field == 'welcome_letter' ||
                field == 'lba' ||
                field == 'post_lba_update' ||
                field == 'lba_foa_response' ||
                field == 'poc_n1_v2_sent' ||
                field == 'EX160_sent'
            ) {
                valueToSave = fieldValue == 'Yes' ? 'sent' : 'not sent'
            }

            if (field == 'poc_n1_v2_signed' || field == 'EX160_signed') {
                valueToSave = fieldValue == 'Yes' ? 'YES' : 'NO'
            }

            if (field == 'foa') {
                valueToSave = fieldValue
            }

            if (field == '' || valueToSave == null) {
                console.log('value or field missing...', field)
                console.log('value or field missing...', valueToSave)
                return console.log('value or field missing...')
            }

            let tempCaseData = { ...caseData }
            tempCaseData[field] = valueToSave
            console.log('data to save : ', tempCaseData)

            setCaseData(tempCaseData)
            setDataChanged(true)
        }
    }

    const checkValue = (field: string) => {
        const fieldValue = caseData[field]

        if (field == 'cancelled' || field == 'poc_issued') {
            const filterValue = fieldValue == 1 ? 'Yes' : 'No'
            return yesNo.filter((option) => option.label === filterValue)
        }
        if (
            field == 'welcome_letter' ||
            field == 'lba' ||
            field == 'post_lba_update' ||
            field == 'lba_foa_response' ||
            field == 'poc_n1_v2_sent' ||
            field == 'EX160_sent'
        ) {
            const filterValue = fieldValue == 'sent' ? 'Yes' : 'No'
            return yesNo.filter((option) => option.label === filterValue)
        }

        if (field == 'poc_n1_v2_signed' || field == 'EX160_signed') {
            const filterValue = fieldValue == 'YES' ? 'Yes' : 'No'
            return yesNo.filter((option) => option.label === filterValue)
        }

        if (field == 'foa') {
            return foaSelect.filter((option) => option.label === caseData['foa'])
        }
    }

    return (
        <div className={styles.detailsView}>
            <h4>
                <span>Progress</span>
                <span>
                    <button onClick={(e) => handleEditToggle()}>{editing ? 'Cancel' : 'Edit'}</button>
                    {editing && dataChanged ? (
                        <>
                            <button onClick={(e) => saveData()}>Save</button>
                        </>
                    ) : (
                        <></>
                    )}
                </span>
            </h4>
            <div>
                <ProgressView data={data} />
            </div>
            <div className={styles.viewContainer}>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <fieldset>
                        <label>Cancelled</label>
                        <Select
                            className={styles.select}
                            options={yesNo}
                            onChange={(value) => onSelectChange(value, 'cancelled')}
                            name={'cancelled'}
                            isDisabled={!editing}
                            value={checkValue('cancelled')}
                        />

                        {/* <Select className={styles.textInput} name={"firstname"} type="text" onChange={onChange} disabled={!editing} /> */}
                    </fieldset>
                    <fieldset>
                        <label>Cancelled Date</label>
                        <input
                            className={styles.textInput}
                            type="text"
                            name={'cancellation_date'}
                            value={caseData.cancellation_date}
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>

                    <fieldset>
                        <label>Signature verification</label>
                        <input
                            className={styles.textInput}
                            name={'signature_verification'}
                            value={caseData.signature_verification}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Signature Score</label>
                        <input
                            className={styles.textInput}
                            name={'signature_score'}
                            value={caseData.signature_score}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Signature Verification Date</label>
                        <input
                            className={styles.textInput}
                            name={'signature_verification_date'}
                            value={caseData.signature_verification_date}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>ID Verification</label>
                        <input
                            className={styles.textInput}
                            name={'id_verification'}
                            value={caseData.id_verification}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>ID Verification Date</label>
                        <input
                            className={styles.textInput}
                            name={'id_verification_date'}
                            value={caseData.id_verification_date}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                    <fieldset>
                        <label>Credit Check Date</label>
                        <input
                            className={styles.textInput}
                            name={'credit_check_date'}
                            value={caseData.credit_check_date}
                            type="text"
                            onChange={onChange}
                            disabled={!editing}
                        />
                    </fieldset>
                </form>
            </div>
        </div>
    )
}
