import { useEffect, useState } from 'react'
import axios from 'axios'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../components/layout/sidebar/sidebar'
import { MainView } from '../components/layout/main'
import { StatTile, ViewTitle } from '../components/reusable'
import api from '../helpers/apiRequester'
import { StatsView } from '../components/views'
import { BreakdownView } from '../components/views/caseListView'
import { ClientsView } from '../components/websiteClaims/views/clientListView'

function WebsiteClaimsClients() {
    const navigate = useNavigate()
    const [dataReady, setDataReady] = useState(false)
    const [loading, setLoading] = useState(false)
    const [clientData, setClientData] = useState<any>()
    const [clientBreakdownData, setClientBreakdownData] = useState<any>()

    useEffect(() => {
        if (!dataReady && !loading) {
            // console.log("LOADING.... GET DATA");
            Loading.circle('Loading...')
            setLoading(true)
            getData()
        }
    }, [dataReady, loading])

    const getData = async () => {
        try {
            const apiResponse = await api('/pcp-sentinel/all-clients')
            const data = apiResponse.data
            // console.log("FOA DATA: ", data);
            const collectedClientData = { stats: data.stats }
            setClientData(collectedClientData)
            const collectedBreakdownClientData = data.paginatedClientData
            setClientBreakdownData(collectedBreakdownClientData)
            setDataReady(true)
            Loading.remove()
        } catch (error: any) {
            console.log(error)
            Loading.remove()
            if (error?.response.status == 401) {
                Report.failure('Session Timed Out', 'Please log in again to continue.', 'Okay', () => {
                    window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`
                })
            } else {
                console.log('')
                window.location.href = `https://${process.env.REACT_APP_DASHBOARD_LOGIN}`
            }
        }
    }

    return (
        <div className="clients">
            <Sidebar />
            <MainView>
                <ViewTitle text={'View Sentinel Media PCP Clients'} />
                <div>{dataReady ? <StatsView stats={clientData.stats} /> : <></>}</div>
                <div>
                    {dataReady ? (
                        <ClientsView
                            dataViewTitle="Search All Clients"
                            dataType="ALL"
                            data={clientBreakdownData}
                            search={true}
                            pagination={true}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </MainView>
        </div>
    )
}

export default WebsiteClaimsClients
