import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { Confirm } from 'notiflix/build/notiflix-confirm-aio'

import api from '../../../../../../helpers/apiRequester'

export const FosTransferAction = (props: { caseRef: string }) => {
    const { caseRef } = props

    const fosCfaTransferRequest = async (action: string) => {
        Loading.circle('Loading...')
        try {
            const apiResponse = await api({ url: '/actions/cfa-transfer', data: { case_ref: caseRef, action } })
            const data = apiResponse.data
            Loading.remove()
            if (action === 'preview' || action === 'preview-doc') {
                if (data.success && data.link) {
                    window.open(data.link, '_newtab')
                } else {
                    Report.failure('Error', 'Failed to complete action: ' + action, 'OKAY')
                }
            } else if (action == 'send') {
                if (data.success && data.loaSent) {
                    Report.success('Success', 'FOS CFA link sent to client.', 'OKAY', () => window.location.reload())
                } else {
                    Report.failure('Error', 'Failed to send FOS CFA link to client', 'OKAY')
                }
            }
        } catch (error: any) {
            console.log(error)
            Loading.remove()
            Report.failure('Error', 'Action failure', 'OKAY')
        }
    }
    const attemptSendfosCfaTransfer = async () => {
        Confirm.show(
            'Are you sure?',
            'You about to send the client a portal link to sign a FOS CFA Transfer document.',
            'SEND CFA link',
            'GO BACK',
            () => fosCfaTransferRequest('send'),
            () => {}
        )
    }

    return (
        <div className={styles.singleAction}>
            <h4>Transfer case to FOS</h4>
            <div>
                <p>
                    <span>Preview FOS CFA document:</span>{' '}
                    <button onClick={(e: any) => fosCfaTransferRequest('preview-doc')}>
                        Generate FOS CFA Document
                    </button>
                </p>
                <p>
                    <span>Preview FOS CFA portal link:</span>{' '}
                    <button onClick={(e: any) => fosCfaTransferRequest('preview')}>View FOS CFA Portal</button>
                </p>{' '}
                <p>
                    <span>Send FOS CFA Portal Link:</span>{' '}
                    <button onClick={(e: any) => attemptSendfosCfaTransfer()}>Send Link</button>
                </p>
            </div>
        </div>
    )
}
