import { Link } from 'react-router-dom'

export const notAuthorised = [
    'c882cf9a-2d7b-466f-952b-ad8d1d496f98', // adl
    '9ed221cd-38ec-4022-82f2-b84cc08604a1',
]

export const SidebarDisplay = (username: string) => {
    return fullSidebar(username)
}

const fullSidebar = (username: string) => {
    return (
        <ul>
            {notAuthorised.some((id) => id == username) ? (
                <></>
            ) : (
                <>
                    {' '}
                    <li>
                        <Link to={'/home'}>Home</Link>
                    </li>
                    <li>
                        <Link to={'/dsar-stats'}>DSAR Stats</Link>
                    </li>
                    <li>
                        <Link to={'/loc-stats'}>LOC Stats</Link>
                    </li>
                    <li>
                        <Link to={'/cases'}>Cases</Link>
                    </li>
                    <li>
                        <Link to={'/clients'}>Clients</Link>
                    </li>
                    <li>
                        <Link to={'/bulk-actions'}>Bulk Actions</Link>
                    </li>
                    <li>
                        <Link to={'/media-claims'}>Media claims</Link>
                    </li>
                    <li>
                        <Link to={'/report-links'}>Report Links</Link>
                    </li>
                </>
            )}
            <li>
                <Link to={'/adl-home'}>ADL Stats</Link>
            </li>
        </ul>
    )
}
const adlSidebar = () => {
    return (
        <ul>
            <li>
                <Link to={'/adl-home'}>Home</Link>
            </li>
        </ul>
    )
}

const oldSidebarLinks = () => {
    return (
        <>
            <li>
                <Link to={'/fos-imports'}>FOS Imports</Link>
            </li>
            <li>
                <Link to={'/introducers'}>Introducers</Link>
            </li>
            <li>
                <Link to={'/FOA'}>FOAs</Link>
            </li>
            <li>
                <Link to={'/FOA-responses'}>FOA Responses</Link>
            </li>
            <li>
                <Link to={'/fos-introducers'}>FOS Introducers</Link>
            </li>
            <li>
                <Link to={'/fos-transfers'}>FOS Transfers</Link>
            </li>
            <li>
                <Link to={'/POC'}>POCs</Link>
            </li>
        </>
    )
}
