import styles from './styles.module.scss'
import pageStyles from '../../../globals/pages.module.scss'
import responsiveStyles from './responsive-styles.module.scss'
import { Wrapper } from '../../reusable'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import logo from '../../../assets/logo.png'
import rightArrow from '../../../assets/right-arrow.png'
import { IsMobile } from '../isMobile/isMobile'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useAuthUserData } from '../../../authUserProvider'
import { SidebarDisplay } from '../../../helpers/sidebarDisplay'

function Sidebar() {
    const authUser = useAuthUserData()
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')

    useEffect(() => {
        if (typeof authUser != 'undefined') {
            setEmail(authUser.email)
            setUsername(authUser.username)
        }
    }, [authUser])
    return (
        <div className={`${styles.sidebar}  ${responsiveStyles.sidebar} `}>
            <div className={styles.sidebarHeader}>
                <img src={logo} alt="dashboard logo" />
            </div>

            <div className={styles.sidebarLinks}>{SidebarDisplay(username)}</div>
        </div>
    )
}

export default Sidebar
