import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { StatTile, ViewTitle } from '../../../reusable'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { useNavigate } from 'react-router-dom'
import api from '../../../../helpers/apiRequester'
import { CaseDetailsView } from '../subViews/caseDetailsView'
import { MilestonesView } from '../subViews/milestonesView'
import { FilesView } from '../subViews/filesView'
import { ActionsView } from '../subViews/actionsView'
// import { CourtDatesView } from '../../subViews/courtDatesView'
export interface statsProps {
    data: any
}
export const CaseView = (props: statsProps) => {
    const { data } = props
    const [showView, setShowView] = useState('details')
    const [activeCase, setActiveCase] = useState(data)

    const views: any = {
        details: <CaseDetailsView data={activeCase} />,
        milestones: <MilestonesView data={activeCase} />,
        files: <FilesView caseRef={activeCase.case_ref} />,
        // courtDates: <CourtDatesView data={activeCase} />,
        actions: (
            <ActionsView
                caseRef={activeCase.case_ref}
                cancelledStatus={data.cancelled}
                part36={{ sf: data.settlement_figure, cn: data.court_claim_number, ds: data.defendant_solicitor }}
            />
        ),
    }

    useEffect(() => {
        // console.log("data to select: ", data);
        setActiveCase(data)
    }, [data])

    const selectView = (view: string) => {
        setShowView(view)
    }

    const getCurrentView = () => {
        return views[showView]
    }

    const caseState = (dupe: number, cancelled: number) => {
        if (dupe === 1) {
            return 'Duplicate'
        }
        if (cancelled === 1) {
            return 'Cancelled'
        }
        return 'Active'
    }

    return (
        <div className={styles.caseView}>
            <div className={styles.outerViewContainer}>
                <div className={styles.caseSidebar}>
                    <ul>
                        <li
                            className={`${showView == 'details' ? styles.active : ''}`}
                            onClick={(e) => selectView('details')}
                        >
                            Details
                        </li>
                        <li
                            className={`${showView == 'milestones' ? styles.active : ''}`}
                            onClick={(e) => selectView('milestones')}
                        >
                            Milestones
                        </li>
                        {/* <li
                            className={`${showView == 'courtDates' ? styles.active : ''}`}
                            onClick={(e) => selectView('courtDates')}
                        >
                            Court
                        </li> */}
                        <li
                            className={`${showView == 'files' ? styles.active : ''}`}
                            onClick={(e) => selectView('files')}
                        >
                            Files
                        </li>
                        <li
                            className={`${showView == 'actions' ? styles.active : ''}`}
                            onClick={(e) => selectView('actions')}
                        >
                            Actions
                        </li>
                    </ul>
                </div>
                <div className={styles.caseViewContainer}>
                    <h3>
                        Case Reference: {activeCase.case_ref}
                        <span className={`${activeCase.cancelled ? styles.inactiveCase : styles.activeCase}`}>
                            {caseState(activeCase.marked_duplicate, activeCase.cancelled)}
                        </span>
                    </h3>
                    <div className={styles.outerCaseView}>{getCurrentView()}</div>
                </div>
            </div>
        </div>
    )
}
